function noop() {}

function getOffsetTop(element) {
  return element.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);
}

function scrollToY(targetY, duration = 400, easing = 'swing', callback = noop) {
  const scrollY = window.scrollY || window.pageYOffset;
  const scrollTargetY = targetY < 0 ? 0 : targetY;
  const diffValue = scrollTargetY - scrollY;
  const startTime = Date.now();
  const easingFunctions = {
    // t: current time, b: start value, c: diff value, d: duration
    linear(t, b, c, d) {
      return c * (t / d) + b;
    },
    swing(t, b, c, d) {
      return c * (0.5 - Math.cos((t / d) * Math.PI) / 2) + b;
    },
  };
  const update = () => {
    const currentTime = Date.now() - startTime;

    if (currentTime < duration) {
      requestAnimationFrame(update);
      window.scrollTo(0, easingFunctions[easing](currentTime, scrollY, diffValue, duration));
    } else {
      window.scrollTo(0, scrollTargetY);
      callback();
    }
  };

  update();
}

export default function scrollTarget(element, duration) {
  let targetY;

  if (element instanceof HTMLElement) {
    targetY = Math.ceil(getOffsetTop(element));

    if (duration === 0) {
      window.scrollTo(0, targetY);
    } else {
      scrollToY(targetY, duration);
    }
  }
}
